/* istanbul ignore file */
// WebPack generation of HTML files under `/demo`
import './demo/index.html';

import * as React from 'react';

import {
  AudiPlatformProvider,
  Breakpoints,
  audiDarkTheme,
} from '@audi/audi-ui-react';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';

import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import AudiFeatureAppVideo from './components/audi-feature-app-video';
import { AudiVideoServiceInterfaceV1 } from '@volkswagen-onehub/audi-video-service';
import { ReactFeatureApp } from '@feature-hub/react';

interface Dependencies extends FeatureServices {
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1 | undefined;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:logger': Logger;
}

export type Video = {
  alt: string;
  aspectRatio: string;
  poster?: string;
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
};

export type Config = {
  video: Partial<Breakpoints<Video>>;
  playsInline: boolean;
  cover?: boolean;
  isFormatable?: boolean;
};

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
>;

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ config, featureServices: services, featureAppId }) => {
    const asyncSsrManager = services['s2:async-ssr-manager'];
    const videoService = services['audi-video-service'];

    if (typeof config.video.xs === 'undefined') {
      throw new Error('bad config: `video.xs` is mandatory');
    }
    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <AudiPlatformProvider theme={audiDarkTheme}>
          <AudiFeatureAppVideo
            cover={config.cover}
            isFormatable={config.isFormatable}
            isServerSide={typeof asyncSsrManager !== 'undefined'}
            playsInline={config.playsInline}
            video={config.video}
            videoService={videoService}
            {...{ featureAppId }}
          />
        </AudiPlatformProvider>
      ),
    };
  },

  dependencies: {
    externals: { react: '^16.13.0||^17.0.2' },
    featureServices: {
      's2:logger': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-video-service': '1.0.0',
      's2:async-ssr-manager': '^1.0.0',
    },
  },
};

export default featureAppDefinition;

import * as React from 'react';

import {
  ButtonFullSizeWrapper,
  MuteButton,
  MuteButtonWrapper,
  PlayButton,
  ReplayButton,
} from '../button';
import { LayoutItem, audiDarkTheme } from '@audi/audi-ui-react';

import { useWindowSize } from '../hooks';

interface IController {
  showPlayButton: boolean;
  showReplayButton: boolean;
  isMuted: boolean;
  showToggleMuteButton: boolean;
  play: () => void;
  replay: () => void;
  toggleMuted: () => void;
  featureAppId: string;
}

const Controller: React.FC<IController> = ({
  showPlayButton,
  play,
  showReplayButton,
  replay,
  toggleMuted,
  isMuted,
  showToggleMuteButton,
  featureAppId,
}) => {
  const { width } = useWindowSize();
  const { m: breakpoint } = audiDarkTheme.breakpoints;

  return (
    <>
      {showPlayButton && (
        <ButtonFullSizeWrapper>
          <PlayButton play={play} />
        </ButtonFullSizeWrapper>
      )}
      {showReplayButton && (
        <ButtonFullSizeWrapper>
          <ReplayButton replay={replay} />
        </ButtonFullSizeWrapper>
      )}
      {showToggleMuteButton && (
        <MuteButtonWrapper id={`${featureAppId}-videoAppMuteButtonWrapper`}>
          <LayoutItem spaceStackEnd={width < breakpoint ? 'l' : 'xl'}>
            <MuteButton
              isMuted={isMuted}
              toggleMuted={toggleMuted}
              windowWidth={width}
            />
          </LayoutItem>
        </MuteButtonWrapper>
      )}
    </>
  );
};

export default Controller;

import * as React from 'react';
import { ReactComponent as AudioOffLarge } from '../../assets/system-audio-off-large.svg';
import { ReactComponent as AudioOffSmall } from '../../assets/system-audio-off-small.svg';
import { ReactComponent as AudioOnLarge } from '../../assets/system-audio-on-large.svg';
import { ReactComponent as AudioOnSmall } from '../../assets/system-audio-on-small.svg';
import { ReactComponent as PlayLarge } from '../../assets/system-play-large.svg';
import { ReactComponent as ReplayLarge } from '../../assets/system-replay-large.svg';
import { audiDarkTheme } from '@audi/audi-ui-react';
import styled from 'styled-components';

const ThemedButton = styled.button`
  background: none;
  border: 0 none;
  color: ${({ theme }): string => theme.colors.text.primary};
  cursor: pointer;
  display: inline-block;
`;

const ToggleSoundSVG: React.FC<{ isMuted: boolean; windowWidth: number }> = ({
  isMuted,
  windowWidth,
}) => {
  const { l: breakpoint } = audiDarkTheme.breakpoints;

  if (isMuted) {
    if (windowWidth < breakpoint) {
      return <AudioOffSmall height="24px" width="24px" />;
    }
    return <AudioOffLarge height="48px" width="48px" />;
  }
  if (windowWidth < breakpoint) {
    return <AudioOnSmall height="24px" width="24px" />;
  }
  return <AudioOnLarge height="48px" width="48px" />;
};

export const PlayButton: React.FC<{ play: () => void }> = (props) => {
  const { play } = props;
  return (
    <ThemedButton onClick={play}>
      <PlayLarge height="48px" width="48px" />
    </ThemedButton>
  );
};

export const ReplayButton: React.FC<{ replay: () => void }> = (props) => {
  const { replay } = props;
  return (
    <ThemedButton onClick={replay}>
      <ReplayLarge height="48px" width="48px" />
    </ThemedButton>
  );
};

export const MuteButtonWrapper = styled.div`
  bottom: 0;
  position: absolute;
  right: 4%;
  z-index: 3;
`;
export const MuteButton: React.FC<{
  toggleMuted: () => void;
  isMuted: boolean;
  windowWidth: number;
}> = (props) => {
  const { toggleMuted, isMuted, windowWidth } = props;

  return (
    <ThemedButton onClick={toggleMuted}>
      <ToggleSoundSVG isMuted={isMuted} windowWidth={windowWidth} />
    </ThemedButton>
  );
};

export const ButtonFullSizeWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

import * as React from 'react';
import { Breakpoints, audiDarkTheme } from '@audi/audi-ui-react';
import { Video } from '../../feature-app';
import styled from 'styled-components';
import { useMemo } from 'react';

const BackgroundImageWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const BackgroundImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

interface IBackgroundImage {
  videos: Partial<Breakpoints<Video>>;
  isFormatable?: boolean;
}

interface ISourceComponet {
  type: string | null;
  breakpoint: number;
  nextBreakpoint: number;
  image: string;
  isFormatable: boolean;
}

const types = ['webp', 'jp2', null];

const SourceComponent: React.FC<ISourceComponet> = (props) => {
  const { breakpoint, nextBreakpoint, image, isFormatable } = props;
  return (
    <source
      media={`(min-width: ${breakpoint}px)${
        nextBreakpoint > 0 ? ` and (max-width: ${nextBreakpoint - 1}px)` : ''
      }`}
      srcSet={`${image}${
        isFormatable
          ? `?${nextBreakpoint > 0 ? `imwidth=${nextBreakpoint - 1}` : ''}`
          : ''
      }`}
    />
  );
};

const BackgroundImageComponent: React.FC<IBackgroundImage> = (props) => {
  const { videos, isFormatable = true } = props;

  // Sorts breakpoints by size and check if video exists for each breakpoint
  const sortedSizesAndFilteredForVideo = useMemo(() => {
    return Object.entries(audiDarkTheme.breakpoints)
      .sort(([, a], [, b]) => a - b)
      .map(([name, number]) => {
        const srcSet = videos[name];
        if (!srcSet) {
          return [name, number];
        }
        return [name, number, srcSet];
      });
  }, [videos]);

  return (
    <BackgroundImageWrapper>
      <picture>
        {sortedSizesAndFilteredForVideo
          .filter(([, , srcSet]) => typeof srcSet !== 'undefined')
          .map(
            (
              [breakPointName, breakpointNumber, srcSet],
              index,
              completeArray,
            ) => {
              const nextVideo = completeArray[index + 1];
              const lastBreakPointNumber =
                sortedSizesAndFilteredForVideo[
                  sortedSizesAndFilteredForVideo.length - 1
                ][1];
              const nextBreakPointNumber = nextVideo
                ? nextVideo[1]
                : lastBreakPointNumber + 1;
              if (!isFormatable) {
                return (
                  <SourceComponent
                    breakpoint={breakpointNumber}
                    image={srcSet.poster}
                    isFormatable={false}
                    key={breakPointName}
                    nextBreakpoint={
                      lastBreakPointNumber + 1 === nextBreakPointNumber
                        ? 0
                        : nextBreakPointNumber
                    }
                    type={null}
                  />
                );
              }

              return sortedSizesAndFilteredForVideo.map(
                ([, bpNumber], i, completeArrayOfBreakPoints) => {
                  const isInRange =
                    bpNumber >= breakpointNumber &&
                    bpNumber < nextBreakPointNumber;

                  if (!isInRange) {
                    return null;
                  }
                  const nextBreakPointNumberBPArray =
                    completeArrayOfBreakPoints[i + 1]?.[1];

                  return types.map((type) => (
                    <SourceComponent
                      breakpoint={bpNumber}
                      image={srcSet.poster}
                      isFormatable={true}
                      key={`${type}-${bpNumber}-${breakPointName}`}
                      nextBreakpoint={
                        nextBreakPointNumberBPArray
                          ? nextBreakPointNumberBPArray
                          : 0
                      }
                      type={type}
                    />
                  ));
                },
              );
            },
          )}

        <BackgroundImage alt={videos['xs'].alt} srcSet={videos['xs'].poster} />
      </picture>
    </BackgroundImageWrapper>
  );
};

export default BackgroundImageComponent;

import * as React from 'react';

import {
  Breakpoints,
  audiDarkTheme,
  responsiveStyles,
} from '@audi/audi-ui-react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AudiVideoServiceInterfaceV1 } from '@volkswagen-onehub/audi-video-service';
import BackgroundImageComponent from './background-image/background-image';
import Controller from './controller/controller';
import { Video } from '../feature-app';
import parseAspectRatio from './utils/parse-aspect-ratio';
import { throttle } from './utils/throttle';
import { useRef } from 'react';

export interface AudiFeatureAppVideoProps {
  video: Partial<Breakpoints<Video>>;
  cover?: boolean;
  isServerSide: boolean;
  videoService: AudiVideoServiceInterfaceV1;
  playsInline: boolean;
  featureAppId: string;
  isFormatable?: boolean;
}

interface WrapperProps {
  isCover?: boolean;
  video: Partial<Breakpoints<Video>>;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ isCover, video, theme }): FlattenSimpleInterpolation =>
    isCover
      ? // Ignore layout tests:
        /* istanbul ignore next */
        css`
          height: 100%;
        `
      : css`
          background: #000;

          ${responsiveStyles(
            { 'padding-bottom': createStyles(video) },
            theme.breakpoints,
          )}

          overflow: hidden;
        `}
  position: relative;
  width: 100%;
`;

const createStyles = (
  video: Partial<Breakpoints<Video>>,
): Breakpoints<string> => {
  return Object.assign(
    {},
    ...Object.entries(video).map(([breakpoint, video]) => {
      const [x, y] = parseAspectRatio(video.aspectRatio);
      // swap sides for "padding-bottom trick"
      const aspectRatioPercentage = (y * 100) / x;

      return { [breakpoint]: `${aspectRatioPercentage}%` };
    }),
  );
};

interface VideoProps {
  isCover?: boolean;
}

const VideoComponent = styled.video<VideoProps>`
  height: 100%;
  ${({ isCover }): FlattenSimpleInterpolation =>
    !isCover &&
    css`
      left: 0;
      position: absolute;
      top: 0;
    `};
  object-fit: ${({ isCover }): string => {
    // Ignore layout tests:
    /* istanbul ignore next */
    return isCover ? 'cover' : 'contain';
  }};
  width: 100%;
`;

const AudiFeatureAppVideo: React.FC<AudiFeatureAppVideoProps> = (props) => {
  const {
    video,
    cover,
    videoService,
    playsInline,
    isServerSide,
    featureAppId,
    isFormatable,
  } = props;
  const getVideoForGivenWidth = useCallback(
    (width: number) => {
      const found = Object.entries(video).reduce(
        (accumulator, [breakpoint, video]) => {
          const minWidth = audiDarkTheme.breakpoints[breakpoint];

          if (accumulator.minWidth <= minWidth && minWidth <= width) {
            return { minWidth, video };
          }

          return accumulator;
        },
        { minWidth: 0, video: null },
      );

      // cast safely to `Video` because breakpoint 'xs' is mandatory and
      // defined with a minimum width of `0`. This is why the `reducer` above
      // will always return a `Video`; never `null`.
      return found.video as Video;
    },
    [video],
  );

  const currentVideo = useMemo(() => {
    if (isServerSide) {
      return null;
    }

    return getVideoForGivenWidth(window.innerWidth);
  }, [getVideoForGivenWidth, isServerSide]);

  const ref = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(currentVideo?.muted);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [isPlaying, setIsPlaying] = useState(currentVideo?.autoPlay);
  const [isAtStart, setIsAtStart] = useState(true);
  const [time, setTime] = useState<number>(0);

  const onEnded = useCallback(() => {
    setShowReplayButton(true);
    setIsPlaying(false);
  }, []);

  const onPlay = useCallback(() => {
    setShowReplayButton(false);
    setIsPlaying(true);
  }, []);

  const toggleMuted = useCallback(() => {
    const muted = videoService.toggleMuted();
    setIsMuted(muted);
  }, [videoService]);

  const hasPosterImage = useCallback((): boolean => {
    return Object.values(video).reduce((accumulator, video) => {
      return accumulator || typeof video.poster !== 'undefined';
    }, false);
  }, [video]);

  useEffect(() => {
    videoService.registerVideoRef(ref);
    return (): void => {
      videoService.unregisterVideoRef();
    };
  }, [videoService]);

  useEffect(() => {
    const videoElement = ref.current;
    const updateTime = (): void => {
      setTime(() => {
        return videoElement.currentTime;
      });
    };

    const setIsNotPlaying = (): void => {
      setIsPlaying(false);
    };

    videoElement?.addEventListener('timeupdate', throttle(updateTime, 250));

    videoElement?.addEventListener('pause', setIsNotPlaying);

    return (): void => {
      videoElement?.removeEventListener(
        'timeupdate',
        throttle(updateTime, 250),
      );

      videoElement?.removeEventListener('pause', setIsNotPlaying);
    };
  }, []);

  useEffect(() => {
    setIsAtStart(() => {
      return time === 0;
    });
  }, [time]);

  useEffect(() => {
    if (isAtStart && !isPlaying) {
      setShowReplayButton(false);
    }
  }, [isAtStart, isPlaying]);

  if (currentVideo === null) {
    return <Wrapper isCover={cover} video={video} />;
  }

  return (
    <Wrapper isCover={cover} video={video}>
      <VideoComponent
        autoPlay={currentVideo.autoPlay}
        isCover={cover}
        key={currentVideo.src}
        loop={currentVideo.loop}
        muted={currentVideo.muted}
        onEnded={onEnded}
        onPlay={onPlay}
        playsInline={playsInline}
        preload="metadata"
        ref={ref}
      >
        <source src={currentVideo.src} />
      </VideoComponent>
      <Controller
        {...{ featureAppId, isMuted, toggleMuted }}
        play={async (): Promise<void> => {
          try {
            await videoService.play();
          } catch (error) {
            // TODO error handling has not yet been specified
            // TODO implement tests once error handling is implemented
            /* istanbul ignore next */
            console.error(error);
          }
        }}
        replay={async (): Promise<void> => {
          setIsPlaying(true);
          setShowReplayButton(false);

          try {
            await videoService.play();
          } catch (error) {
            // TODO error handling has not yet been specified
            // TODO implement tests once error handling is implemented
            /* istanbul ignore next */
            console.error(error);
          }
        }}
        showPlayButton={
          !currentVideo.autoPlay &&
          !isPlaying &&
          isAtStart &&
          !(!currentVideo.loop && showReplayButton)
        }
        showReplayButton={!currentVideo.loop && showReplayButton}
        showToggleMuteButton={!currentVideo.muted}
      />
      {hasPosterImage() && !isPlaying && !showReplayButton && isAtStart && (
        <BackgroundImageComponent {...{ isFormatable }} videos={video} />
      )}
    </Wrapper>
  );
};

export default AudiFeatureAppVideo;

import { useEffect, useState } from 'react';

export const useWindowSize = (): {
  height: number;
  width: number;
} => {
  const [state, setstate] = useState({
    height: window?.innerHeight || 0,
    width: window?.innerWidth || 0,
  });

  useEffect(() => {
    function handleResize(): void {
      setstate({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window?.addEventListener('resize', handleResize);
    return (): void => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return state;
};

type Callback = (...args: unknown[]) => void;
export const throttle = (fn: Callback, wait: number): Callback => {
  let isCalled: boolean;
  return (...args): void => {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(() => (isCalled = false), wait);
    }
  };
};
